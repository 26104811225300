import React, { useState } from 'react';
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import "react-modal-video/scss/modal-video.scss";
import ContactButton from './contactbutton2';
import '../component/common/swiper.css';
import RandomImageComponent from './randomimg';
import Songlist from './modal';
import Mmodalyt from '../dark/modalyt';
import ShareButton from '../dark/sharebutton'; 

const imageArray2 = [
  'https://i.imgur.com/jDESwGR.png',
  'https://i.imgur.com/lwICfDE.png',
  'https://i.imgur.com/MXYJg2M.png',
  'https://i.imgur.com/zHU3sVl.png',
  'https://i.imgur.com/W687FqV.png',
  'https://i.imgur.com/qdGJhl1.jpeg',
  'https://i.imgur.com/oy8moOK.jpeg',
  'https://i.imgur.com/9qtCP2c.jpeg',
  'https://i.imgur.com/ksFx9pp.jpeg',
  'https://i.imgur.com/xmbL8zi.jpeg',
  'https://i.imgur.com/wXN3Pc4.jpeg',
  'https://i.imgur.com/5p7FbcC.jpeg',
  'https://i.imgur.com/yvJFN00.jpeg',
  'https://i.imgur.com/giH2DBu.jpeg',
  'https://i.imgur.com/bEkjU8U.jpeg',
];

const Aboutus = () => {
  // eslint-disable-next-line
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="active-dark">
      <Helmet pageTitle="River Cats" />
      <HeaderThree id="home" homeLink="/" logo="symbol-dark" color="color-black" />
      <section></section>
      <div id="portfolio">
        <div className="portfolio-area ptb--120 bg_color--1">
        <center><h1>Future Events</h1></center>
          <div className="portfolio-sacousel-inner">
            <br />
            <section className="light">
              <div className="container py-2">
                
              <article className="gallery1234">
              
              <div className="l-container">
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/KUYTRcd.png')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Raxx Bar & Grill</h2>
                          <h3 className="b-game-card__subtitle">March 8th - 8:30PM</h3>
                          <p className="b-game-card__description">We will be playing at Raxx in kingston</p>
                        </div>
                      </div>
                    </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/CtyCgaI.png')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Little Texas</h2>
                          <h3 className="b-game-card__subtitle">April 12 - 8:00PM</h3>
                          <p className="b-game-card__description">We will be playing at Little Texas in Bellleville</p>
                        </div>
                      </div>
                    </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/pwXSEWK.png')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Music Fest</h2>
                          <h3 className="b-game-card__subtitle">May 24- 7:30PM</h3>
                          <p className="b-game-card__description">We will be playing at the South Frontenac Music Fest in Harrowsmith</p>
                        </div>
                      </div>
                    </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/KhZtrGV.png')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Gananoque Ribfest</h2>
                          <h3 className="b-game-card__subtitle">June 29th - 8:00PM</h3>
                          <p className="b-game-card__description">We will be playing at the Gananoque Ribfest</p>
                        </div>
                      </div>
                    </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/ow8gYu9.png')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Rays Cottages</h2>
                          <h3 className="b-game-card__subtitle">August 2nd - 6:00PM</h3>
                          <p className="b-game-card__description">We be playing at Rays Cottages again this year!</p>
                        </div>
                      </div>
                    </div>
               </div>
          </article>
                <br />
                <ContactButton />
                <br />
                <center><h1>Past Events</h1></center>
                <br />
                <div className="container py-2">
                  <article className="gallery123">
                  <div className="l-container">
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/G0sZxC9.jpeg')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Blue Martini</h2>
                          <h3 className="b-game-card__subtitle">Thursday, May 2 - 8:00PM</h3>
                          <p className="b-game-card__description">We played the Royal Jam at the Blue Martini <br /><Mmodalyt /></p>
                        </div>
                      </div>
                    </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/uzWKn0P.jpeg')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">The Cultural Exchange</h2>
                          <h3 className="b-game-card__subtitle">Saturday, May 4 - 5:00PM</h3>
                          <p className="b-game-card__description">We played for the 26th Cultural Exchange Raid train! <a href="https://www.twitch.tv/river_cats" target='_blank' rel="noreferrer" >Watch Live</a></p>
                        </div>
                      </div>
                    </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/tfvgHDL.jpeg')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">Waterfront Music Series</h2>
                        <h3 className="b-game-card__subtitle">Friday, July 12</h3>
                        <p className="b-game-card__description">We played in Gananoque for the Waterfront Music Series</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/Gj4HBKy.jpeg')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">Girls Night Out @Royal Tavern 2.0</h2>
                        <h3 className="b-game-card__subtitle">Friday, July 26 9PM-12:30AM</h3>
                        <p className="b-game-card__description">We played in Kingston at the Royal Tavern 2.0 from 9pm to 12:30AM</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/lVCE9ui.jpeg')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">Rays Cottages</h2>
                        <h3 className="b-game-card__subtitle">Saturday, August 3, 2024</h3>
                        <p className="b-game-card__description">We Played at Rays Cottages!</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/ISp7jX4.png')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">Confederation Basin</h2>
                        <h3 className="b-game-card__subtitle">Saturday, August 10th 1-2PM</h3>
                        <p className="b-game-card__description">We played at Confederation Basin in Kingston it was a blast!!</p>
                      </div>
                    </div>
                  </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/aLUrqK9.jpeg')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">The Cultural Exchange</h2>
                        <h3 className="b-game-card__subtitle">Saturday, August 24th 2-4PM</h3>
                        <p className="b-game-card__description">We played for the 27th Cultural Exchange Raid train! <a href="https://www.youtube.com/watch?v=i7nNofCmGP4" target='_blank' rel="noreferrer" >Watch it here!</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="b-game-card">
                    <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/omoX6o4.jpeg')" }}>
                      <div className="b-game-card__content">
                        <h2 className="b-game-card__title">Lower Beverly Lake Park</h2>
                        <h3 className="b-game-card__subtitle">Saturday, September 7th</h3>
                        <p className="b-game-card__description">We played at the Lower Beverly Lake Park For the Old Bastards Vintage Rider Rally 2024</p>
                      </div>
                    </div>
                  </div>
                    <div className="b-game-card">
                      <div className="b-game-card__cover" style={{ backgroundImage: "url('https://i.imgur.com/J7VgF8A.jpeg')" }}>
                        <div className="b-game-card__content">
                          <h2 className="b-game-card__title">Little Texas</h2>
                          <h3 className="b-game-card__subtitle">Saturday, Nov 9th - 9-11:30PM</h3>
                          <p className="b-game-card__description">We played a couple sets at little texas in Belleville Ontario. <a href="https://www.littletexas.ca/" target='_blank' rel="noreferrer" >More Info Here</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </article>
                </div>
                <br />
                <br />
                <article className="postcard light yellow">
                  <div className="postcard__img_link">
                    <RandomImageComponent imageArray={imageArray2} className="postcard__img" />
                  </div>
                  <div className="postcard__text t-dark">
                    <h1 className="postcard__title yellow">Follow us everywhere!</h1>
                    <div className="postcard__subtitle small"></div>
                    <div className="postcard__bar"></div>
                    <div className="postcard__preview-txt">
                      <p>Join us on these platforms to immerse yourself in a world of musical wonders. Experience the magic firsthand by catching our electrifying live performances, where the stage comes alive with the energy of the River Cats. Dive into the heart of our creative process and discover exclusive behind-the-scenes moments that unveil the passion and dedication fueling our music.</p>
                      <p>Stay tuned for the latest updates and exclusive content! Follow us on:</p>
                    </div>
                    <ul className="postcard__tagbox">
                      <a href="https://www.youtube.com/@River-Cats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Youtube</li></a>
                      <a href="https://www.facebook.com/RiverCatsYGK/" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Facebook</li></a>
                      <a href="https://www.twitch.tv/river_cats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Twitch</li></a>
                      <a href="https://www.reverbnation.com/rivercats" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Reverbnation</li></a>
                      <a href="https://www.instagram.com/rivercatsygk/" target="_blank" rel="noreferrer"><li className="tag__item"><i className="fas fa-tag mr-2"></i>Instagram</li></a>
                    </ul>
                    <br /><Songlist />
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
      <FooterTwo />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default Aboutus;
